export const normalizeContentfulFeatureNodes = (allContent) => {

  const content = allContent[0]

  return {
    hero: {
      text: [
          {
            "text": content.title,
            "color": "omegaDarker",
            "variant": "h1"
          },
          {
            "text": content.subtitle.subtitle,
            "color": "omegaDark",
            "variant": "p"
          }
        ],
        images: [
          {
            src: content.hero,
            "alt": "alt text",
            "radius": "lg",
            "shadow": "xl",
            "effects": ["fadeInLeft"]
          }
        ],
        "buttons": [
          {
            "text": "Get Started",
            "type": "PAGE",
            "link": "https://sheetgurus.com/account/signup?referrer=website_feature",
            "variant": "primary"
          }
        ]
      },
      }
    }
